import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/pawel/Projekty/wyspiewana-tradycja/src/layouts/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Informacje o projekcie`}</h1>
    <p><em parentName="p">{`Zespół to było KGW, które śpiewało. Panie i Panowie śpiewali pieśni stąd, zasłyszane od pokoleń. W czasie wojny spotykali się po domach. Po wojnie głównym zajęciem Koła Gospodyń był jednak śpiew.`}</em>{` (Górzanki)`}</p>
    <p>{`Projekt `}<strong parentName="p">{`Wyśpiewana tradycja`}</strong>{` ma na celu popularyzację wiedzy o lokalnych, gminnych zespołach śpiewaczych.
Powstał z idei zachowania pamięci o ruchu śpiewaczym Zagłębia Dąbrowskiego.`}</p>
    <p>{`Dzięki stworzeniu różnych aktywności projektowych (szkolenia, wywiady, zbieranie fotografii) spotkały się w nim 2 rzeczywistości: naukowa próba uchwycenia fenomenu i żywa tradycja.`}</p>
    <p>{`Efektem tego zderzenia jest otwarta, dostępna strona zespolyspiewacze.pl prezentująca zaledwie kilka zespołów śpiewaczych spośród wszystkich działających w regionie.`}</p>
    <p>{`Mamy nadzieję, że dzięki funkcji dodawania do niej nowych zgłoszeń będzie ona wciąż żyjącym repozytorium tej tradycji.`}</p>
    <h2>{`Metoda badawcza`}</h2>
    <p>{`Transkrypcja materiałów etnomuzykologicznych`}</p>
    <p>{`Transkrypcja nagranego materiału muzycznego wymaga od etnomuzykologa wiedzy i doświadczenia. W Polsce obowiązuje metoda, którą w latach 60.XX rozpropagowali muzykolodzy z ośrodka poznańskiego, tj. małżeństwo - Jadwiga Sobieska (1909-1995) i Marian Sobieski (1908-1967). Warstwa muzyczna zanotowana jest na pięciolinii w taki sposób, by uniknąć dużej liczby znaków chromatycznych, a nuta romboidalna (na początku zapisu) to faktyczny dźwięk, od którego śpiewacy zaczynali melodię. W związku z tym pomija się również zapis tonacji, umieszczając znaki chromatyczne przy poszczególnych nutach.`}</p>
    <h2>{`Osoby zaangażowane w realizację projektu`}</h2>
    <ul>
      <li parentName="ul">{`Koordynacja: Agata Hofelmajer-Roś`}</li>
      <li parentName="ul">{`Zespół: Magdalena Hak, Michał Wyszyński`}</li>
      <li parentName="ul">{`Dostępność: Anna Korpak`}</li>
    </ul>
    <p>{`Kierowniczki, kierownicy, członkinie, członkowie zespołów: Brzękowianie, Dąbie, Górzanki, Kapela Gródków, Nasz Gródków, OD-DO, Strzyżowiczanie, Tęcza, Zagłębianki`}</p>
    <p>{`Projekt strony: Jan Piechota
Programowanie: Paweł Marynowski`}</p>
    <p>{`Fotografie: Michał Jędrzejowski`}</p>
    <p>{`Instytucja kultury: Anna Nagły i Gminny Ośrodek Kultury Gminy Psary
Instytucja samorządowa: gmina Psary w województwie śląskim`}</p>
    <p>{`Środowisko eksperckie:`}</p>
    <ul>
      <li parentName="ul">{`dr hab. Magdalena Szyndler, prof. UŚ - szkolenia, opracowanie treści merytorycznych`}</li>
      <li parentName="ul">{`dr hab. Joanna Glenc, prof. UŚ - szkolenia`}</li>
      <li parentName="ul">{`mgr Agata Krajewska - materiały edukacyjne`}</li>
    </ul>
    <h2>{`Nasi partnerzy`}</h2>
    <ul>
      <li parentName="ul">{`Instytut Myśli Polskiej im. Wojciecha Korfantego`}</li>
      <li parentName="ul">{`Instytut im. Oskara Kolberga`}</li>
      <li parentName="ul">{`Fundacja Kultura Obrazu`}</li>
    </ul>
    <h2>{`Dofinansowanie`}</h2>
    <p>{`Dofinansowano ze środków Narodowego Centrum Kultury w ramach Programu Kultura w sieci`}</p>
    <div className="d-flex align-items-center">
  <img className="ml-n3" alt="logo Ministerstwo Kultury i Dziedzictwa Narodowego. logo Narodowe Centrum Kultury. Dofinansowano ze środków Narodowego Centrum Kultury w ramach Programu Kultura w sieci" src="../logo-kultura-w-sieci.png" style={{
        height: 120
      }} />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      